import React, { useContext, useEffect, useState } from "react";
import { ContactContext } from "../../contexts/ContactContext";
import moment from "moment";

function Contact() {
  const [contacts, setContacts] = useState({
    contacts: [],
    currentPage: "",
    totalItems: "",
    totalPages: "",
  });
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const { getContact, deleteContact } = useContext(ContactContext);

  const fetchContact = async () => {
    const res = await getContact(page, size);
    if (res.contacts) {
      res.contacts.map((contact) => {
        contact.view = false;
      });
    }
    setContacts(res);
  };

  useEffect(() => {
    fetchContact();
  }, [page, size]);

  useEffect(() => {}, [contacts]);

  const handleContactView = (index) => {
    let newContacts = [...contacts.contacts];
    newContacts[index].view = !newContacts[index].view;

    setContacts((contacts) => ({ ...contacts, contacts: newContacts }));
  };

  const handleDeleteContact = async (id) => {
    if (window.confirm("Are you sure want to delete this contact?")) {
      await deleteContact(id);
      fetchContact();
    }
  };

  return (
    <div>
      <div className="flex flex-col items-center justify-center ">
        <div className="bg-white p-10 rounded-lg min-h-[500px] h-fit">
          <h1>contact</h1>
          <div className="w-[1000px] flex gap-4 p-2 justify-between">
            <div className="flex gap-4">
              <select
                className="w-14 h-7 border border-gray-300"
                onChange={(e) => {
                  setSize(e.target.value);
                  setPage(1);
                }}
              >
                <option value="10">10</option>
                <option value="25">25</option>
              </select>
              records per page
            </div>
            <div>
              <button
                className="p-1 px-3 border rounded-l-lg hover:bg-gray-300"
                onClick={() => {
                  if (page > 1) {
                    setPage(page - 1);
                  }
                }}
              >
                Previous
              </button>
              {contacts.totalPages &&
                [...Array(contacts.totalPages)].map((_, index) => {
                  return (
                    <button
                      className={`p-1 w-10 border hover:bg-gray-300 ${
                        index + 1 === page && "bg-gray-300"
                      }`}
                      key={index}
                      onClick={() => {
                        setPage(index + 1);
                      }}
                    >
                      {index + 1}
                    </button>
                  );
                })}
              <button
                className="p-1 px-3 border rounded-r-lg hover:bg-gray-300"
                onClick={() => {
                  if (page < contacts.totalPages) {
                    setPage(page + 1);
                  }
                }}
              >
                Next
              </button>
            </div>
          </div>
          <div className="flex w-[1000px] justify-center items-center text-center bg-gray-300 p-3">
            <div className="w-full basis-1/12">#</div>
            <div className="w-full basis-1/5">Name</div>
            <div className="w-full basis-1/5">Organization/Company</div>
            <div className="w-full basis-1/5">Email</div>
            <div className="w-full basis-1/5">Date</div>
            <div className="w-full basis-1/6">Option</div>
          </div>
          {contacts.contacts.map((contact, index) => {
            return (
              <div
                className="flex flex-col w-[1000px] justify-center items-center text-center border-b border-x p-3"
                key={index}
              >
                <div className="flex w-full justify-center items-center text-center">
                  <div className="w-full basis-1/12">
                    {(page - 1) * size + index + 1}
                  </div>
                  <div className="w-full basis-1/5">{contact.name || "-"}</div>
                  <div className="w-full basis-1/5">
                    {contact.company || "-"}
                  </div>
                  <div className="w-full basis-1/5">{contact.email || "-"}</div>
                  <div className="w-full basis-1/5">
                    {moment(contact.createdAt).format("HH:mm DD MMMM YYYY")}
                  </div>
                  <div className="w-full basis-1/6 flex justify-center gap-2">
                    <button
                      className={`text-white rounded-md px-3 py-1 ${
                        contact.view === false
                          ? "bg-blue-500 hover:bg-blue-600"
                          : "bg-yellow-500 hover:bg-yellow-600"
                      }`}
                      onClick={() => handleContactView(index)}
                    >
                      {contact.view === false ? "View" : "Hide"}
                    </button>
                    <button
                      className="bg-red-500 hover:bg-red-600 text-white rounded-md px-3 py-1"
                      onClick={() => handleDeleteContact(contact.id)}
                    >
                      Delete
                    </button>
                  </div>
                </div>
                <div
                  className={`m-3 p-5 bg-gray-300 w-full rounded-lg  ${
                    contact.view === false && "hidden"
                  }`}
                >
                  <div className="flex gap-10 justify-start w-full text-left">
                    <div>
                      <div className="font-semibold">
                        What is your company Website ?
                      </div>
                      <div>{contact.website || "-"}</div>
                    </div>
                    <div>
                      <div className="font-semibold">
                        Services required from us
                      </div>
                      <div>{contact.service || "-"}</div>
                    </div>
                    <div>
                      <div className="font-semibold">Phone number</div>
                      <div>{contact.phone || "-"}</div>
                    </div>
                  </div>
                  <div className="text-left">
                    <div className="font-semibold">Remarks</div>
                    <div>{contact.remark || "-"}</div>
                  </div>
                </div>
              </div>
            );
          })}
          {contacts.contacts.length === 0 && (
            <div className="flex flex-col w-[1000px] justify-center items-center text-center border-b border-x p-3">
              no information
            </div>
          )}
          <div className="mt-2">
            Showing {(page - 1) * size + 1} to{" "}
            {page * size > contacts.totalItems
              ? contacts.totalItems
              : page * size}{" "}
            of {contacts.totalItems} entries
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
