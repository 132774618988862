import "./App.css";
import Router from "./routers/Router";

function App() {
  return (
    <>
      <div className=" App font-Kanit">
        <Router />
      </div>
    </>
  );
}

export default App;
