import axios from "axios";
import { createContext } from "react";

export const ContactContext = createContext();

function ContactContextProvider({ children }) {
  const getContact = async (page, size) => {
    const res = await axios.get(
      `https://api.theamiris.com/web/contact?page=${page}&size=${size}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    return res.data;
  };

  const deleteContact = async (id) => {
    const res = await axios.delete(
      `https://api.theamiris.com/web/contact/${id}`,
      {
        headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
      }
    );

    return res;
  };

  return (
    <ContactContext.Provider
      value={{
        getContact,
        deleteContact,
      }}
    >
      {children}
    </ContactContext.Provider>
  );
}

export default ContactContextProvider;
